<template>
	<div>
		<div class="supplier_actions_btns has_two_btn" id="payrollCode" v-if="styleType === 'button'" @click="handleClickManagePayrollBtn">
			<b-button>
				<component :is="getLucideIcon('Cog')" color="#FFFFFF" :size="20" />
			</b-button>
			<b-button block variant="outline-primary">{{ FormMSG(25, 'Manage payroll codes...') }}</b-button>
		</div>
		<v-select
			v-if="styleType === 'select'"
			class="style-payroll-code"
			v-model="payrollCodeSelected"
			:options="pCodes"
			:disabled="disabled"
			label="code"
			:reduce="(option) => option.id"
			:clearable="selectClearable"
			:placeholder="FormMSG(449, 'Payroll code ...')"
			@input="handleInputSelectPayrollCode"
		>
			<template #no-options="{}">
				{{ FormMSG(468, 'No payroll code found') }}
			</template>
		</v-select>
		<div v-if="styleType === 'button'">
			<b-modal
				v-model="showModal"
				:title="FormMSG(79, 'Payroll code list')"
				header-class="header-class-modal-doc-package"
				size="lg"
				no-close-on-esc
				no-close-on-backdrop
				hide-header-close
				cancel-variant="outline-primary"
				:cancel-title="FormMSG(81, 'Close')"
				:ok-title="FormMSG(80, 'Add new code')"
				@ok.prevent="handleClickAddPayrollCode"
				@hidden="closeModal"
				@cancel="closeModal"
			>
				<div>
					<b-row class="mb-3">
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-input-group v-if="$screen.width >= 992">
								<b-form-input type="text" v-model="filter" :placeholder="FormMSG(65, 'Type to search')" /><!-- @change="handleChangeFilter" -->
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="filter = ''" v-else />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<CardListBuilder v-if="$screen.width < 992" :items="pCodes" :fields="fields">
								<template slot="actions" slot-scope="data">
									<div class="mt-3">
										<b-button variant="danger" size="sm" @click="handleClickDeleteItem(data.item)">
											<component :is="getLucideIcon('Trash2')" :size="16" />
										</b-button>
									</div>
								</template>
							</CardListBuilder>
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="payrollTable"
								sticky-header="700px"
								:items="tpCodes"
								style="text-align: left"
								:fields="fields"
								:filter="filter"
								bordered
								small
								head-variant="dark"
								:empty-text="FormMSG(158, 'No payroll code found')"
								show-empty
							>
								<template #cell(code)="data">
									<col style="width: 160px" />
									<inline-input
										:data="data"
										:placeholder="FormMSG(2, 'Your code ...')"
										:id-name="EL_NAME_CODE"
										:value-attribute="'code'"
										:isSubmitted="isSubmitted"
										:error="$v.payCode.$error"
										@payroll-code-inline-input:valid-data="removeConstraint"
										class="w-100"
									/>
								</template>
								<template #cell(description)="data">
									<col style="width: 160px" />
									<inline-input
										:data="data"
										:placeholder="FormMSG(3, 'Your description ...')"
										:id-name="EL_NAME_DESC"
										:value-attribute="'description'"
									/>
								</template>
								<template #cell(edit)="data">
									<inline-button
										:data="data"
										@payroll-code-inline-button:updated="handleUpdate(data)"
										@payroll-code-inline-button:saved="handleSave(data.item.id)"
									/>
								</template>
								<template #cell(remove)="data">
									<div>
										<button v-if="data.item.id > 0" class="btn-transparent text-danger" @click="handleClickDeleteItem(data.item.id)">
											<component :is="getLucideIcon('Trash2')" :size="20" :stroke-width="2" />
										</button>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import InlineInput from '@/components/PayrollCode/InlineInput';
import InlineButton from '@/components/PayrollCode/InlineButton';
import { generateSecureId } from '@/shared/utils';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const TypeValidators = ['button', 'select'];

export default {
	name: 'PayrollCode',
	props: {
		styleType: { type: String, default: 'button', validator: (v) => TypeValidators.includes(v) },
		btnVariant: { type: String, default: 'outline-primary' },
		btnSize: { type: String, default: 'sm' },
		value: { type: [String, Number], required: false },
		selectClearable: { type: Boolean, default: true, required: false },
		disabled: { type: Boolean, default: false, required: false }
	},
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		InlineInput,
		InlineButton
	},
	data() {
		const EL_NAME_CODE = 'prc';
		const EL_NAME_DESC = 'prd';

		return {
			pCodes: [],
			tpCodes: [],
			showModal: false,
			filter: '',
			payrollCodeSelected: null,
			EL_NAME_CODE: EL_NAME_CODE,
			EL_DIV_NAME_CODE: EL_NAME_CODE + '_div_',
			EL_INPUT_NAME_CODE: EL_NAME_CODE + '_input_',
			EL_INLINE_NAME_CODE: EL_NAME_CODE + '_inline_',
			EL_NAME_DESC: EL_NAME_DESC,
			EL_DIV_NAME_DESC: EL_NAME_DESC + '_div_',
			EL_INPUT_NAME_DESC: EL_NAME_DESC + '_input_',
			EL_INLINE_NAME_DESC: EL_NAME_DESC + '_inline_',
			EL_BTN_EDIT: 'btn_edit_',
			EL_BTN_SAVE: 'btn_save_',
			isSubmitted: false
		};
	},
	watch: {
		payrollCodes: {
			handler(newVal) {
				this.pCodes = [
					{
						id: null,
						code: `${this.FormMSG(1, 'Pay code ...')}`
					}
				];
				this.pCodes = [...this.pCodes, ...newVal];

				this.tpCodes = [];
				this.tpCodes = newVal;
			},
			immediate: true,
			deep: true
		},
		value: {
			handler(newVal) {
				if (!_.isNil(newVal)) {
					this.payrollCodeSelected = '' + newVal;
				}
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		...mapGetters({
			payrollCodes: 'payroll-code/payrollCodes',
			currentPayroolCode: 'payroll-code/currentPayroolCode'
		}),
		fields() {
			return [
				{
					key: 'code',
					label: this.FormMSG(123, 'Code'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(121, 'Description'),
					sortable: true
				},
				{
					key: 'edit',
					label: this.FormMSG(125, 'Edit'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'remove',
					label: this.FormMSG(124, 'Remove'),
					sortable: false,
					class: 'text-center'
				}
			];
		}
	},
	async created() {
		await this.getPayrollCodes();
	},
	methods: {
		...mapActions({
			getPayrollCodes: 'payroll-code/getPayrollCodes',
			addUpdPayrollCode: 'payroll-code/addUpdPayrollCode',
			deletePayrollCode: 'payroll-code/deletePayrollCode'
		}),
		handleInputSelectPayrollCode(payload) {
			if (!_.isNil(this.payrollCodeSelected)) {
				this.$emit('payroll-code:input', payload);
			} else {
				this.$emit('payroll-code:reset', true);
			}
		},
		async handleClickAddPayrollCode() {
			await this.addUpdPayrollCode({
				id: 0,
				code: '',
				description: ''
			}).then(() => {
				this.showInput(this.currentPayroolCode.id);
			});
		},
		handleClickManagePayrollBtn() {
			this.showModal = true;
		},
		async handleInputCode(payload, item) {
			await this.addUpdPayrollCode({
				id: item.id,
				code: payload,
				description: item.description
			});
		},
		async handleChangeDescription(payload, item) {
			await this.addUpdPayrollCode({
				id: item.id,
				code: item.code,
				description: payload
			});
		},
		async handleClickDeleteItem(id, useConfirmModal = true) {
			if (useConfirmModal) {
				const action = async () => {
					this.deletePayrollCode(id);

					this.createToastForMobile(this.FormMSG(145, 'Success'), this.FormMSG(146, 'Payroll code deleted successfully!'));
				};

				this.confirmModal(action, this.FormMSG(148, 'Are you sure ?'));
			} else {
				this.deletePayrollCode(id);
			}
		},
		handleUpdate(data) {
			this.showInput(data.item.id);
			this.clickRow(data.index);
			this.payrollCodeSelected = data.item.id;
			this.isSubmitted = false;
		},
		async handleSave(id) {
			this.isSubmitted = true;

			const code = document.getElementById(`${this.generateTextId(this.EL_INPUT_NAME_CODE + id)}`).value;
			const description = document.getElementById(`${this.generateTextId(this.EL_INPUT_NAME_DESC + id)}`).value;

			if (code.trim() === '') {
				this.$v.$touch();
				if (this.$v.$invalid) {
					document.getElementById(`${this.generateTextId(this.EL_INPUT_NAME_CODE + id)}`).focus();
					return;
				}
			}

			this.hideInput(id);

			await this.addUpdPayrollCode({
				id,
				code,
				description
			}).then(() => {
				this.isSubmitted = false;
			});
		},
		hideInput(id) {
			document.getElementById(`${this.generateTextId(this.EL_DIV_NAME_CODE + id)}`).style.display = 'none';
			document.getElementById(`${this.generateTextId(this.EL_DIV_NAME_DESC + id)}`).style.display = 'none';
			document.getElementById(`${this.generateTextId(this.EL_INLINE_NAME_CODE + id)}`).style.display = 'block';
			document.getElementById(`${this.generateTextId(this.EL_INLINE_NAME_DESC + id)}`).style.display = 'block';
			document.getElementById(`${this.generateTextId(this.EL_BTN_SAVE + id)}`).style.display = 'none';
			document.getElementById(`${this.generateTextId(this.EL_BTN_EDIT + id)}`).style.display = 'block';
		},
		showInput(id) {
			document.getElementById(`${this.generateTextId(this.EL_DIV_NAME_CODE + id)}`).style.display = 'block';
			document.getElementById(`${this.generateTextId(this.EL_DIV_NAME_DESC + id)}`).style.display = 'block';
			document.getElementById(`${this.generateTextId(this.EL_INLINE_NAME_CODE + id)}`).style.display = 'none';
			document.getElementById(`${this.generateTextId(this.EL_INLINE_NAME_DESC + id)}`).style.display = 'none';
			document.getElementById(`${this.generateTextId(this.EL_BTN_SAVE + id)}`).style.display = 'block';
			document.getElementById(`${this.generateTextId(this.EL_BTN_EDIT + id)}`).style.display = 'none';
			document.getElementById(`${this.generateTextId(this.EL_INPUT_NAME_CODE + id)}`).focus();
		},
		clickRow(idx) {
			this.$nextTick(function () {
				if (!_.isNil(this.$refs.payrollTable)) {
					let table = this.$refs.payrollTable.$el,
						tableBody = table.getElementsByTagName('tbody')[0],
						tableRows = tableBody.getElementsByTagName('tr');
					tableRows[idx].click();
				}
			});
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		removeConstraint(payload) {
			if (payload) {
				this.isSubmitted = false;
			}
		},
		removeInvalidData(payload) {
			if (payload) {
				this.deletePayrollCode(payload);
			}
		},
		async closeModal() {
			for (let i = 0; i < this.tpCodes.length; i++) {
				const element = this.tpCodes[i];
				if (element.code === '') {
					await this.handleClickDeleteItem(element.id, false);
				}
			}
			this.showModal = false;
		}
	},
	validations: {
		payCode: {
			required
		}
	}
};
</script>

<style lang="scss" scoped></style>
