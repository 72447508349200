<template>
	<div class="d-flex justify-content-center align-items-center">
		<div :id="generateTextId(`btn_edit_${data.item.id}`)">
			<button class="btn-transparent text-danger" @click="handleUpdate(data)">
				<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" :stroke-width="2" />
			</button>
		</div>
		<div :id="generateTextId(`btn_save_${data.item.id}`)" style="display: none">
			<button class="btn-transparent text-danger" @click="handleSave(data.item.id)" :id="generateTextId(`tooltip_${data.item.id}`)">
				<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" color="#3C7442" :size="20" :stroke-width="2" />
			</button>
		</div>
		<b-tooltip :target="generateTextId(`tooltip_${data.item.id}`)" show placement="left">{{ FormMSG(1, 'Save or Escape') }}</b-tooltip>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { generateSecureId } from '@/shared/utils';

export default {
	name: 'PayrollCodeInlineButton',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		data: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	methods: {
		generateTextId(id) {
			return generateSecureId(id);
		},
		handleUpdate(item) {
			this.$emit('payroll-code-inline-button:updated', item);
		},
		handleSave(id) {
			this.$emit('payroll-code-inline-button:saved', id);
		}
	}
};
</script>
