import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';

const calendarField = `
  id
  createdAt
  updatedAt
  projectId
  calNumber
  name
`;

const publicHolidayField = `
  id
  calNumber
  date
  name
  shortName
  rate
  paid
  color
  paidStr
  publicHolidayCalendar {
    id
    createdAt
    updatedAt
    projectId
    calNumber
    name
  }
`;

export const getPublicHolidayCalendar = async (PublicHolidayCalendarNumber) => {
	try {
		const QUERY_GET_PUBLIC_HOLIDAY_CALENDAR = gql`
      query($PublicHolidayCalendarNumber: ID!) {
        GetPublicHolidayCalendar(PublicHolidayCalendarNumber: $PublicHolidayCalendarNumber) {
          ${calendarField}
        }
      }
    `;

		const {
			data: { GetPublicHolidayCalendar }
		} = await apollo.query({
			query: QUERY_GET_PUBLIC_HOLIDAY_CALENDAR,
			variables: {
				PublicHolidayCalendarNumber: parseInt(PublicHolidayCalendarNumber, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetPublicHolidayCalendar;
	} catch (e) {
		console.log(e);
	}
};

export const getProjectPublicHolidayCalendar = async (orderByName = false) => {
	try {
		const QUERY_GET_PROJECT_PUBLIC_HOLIDAY_CALENDAR = gql`
			query ($orderByName: Boolean) {
				GetProjectPublicHolidayCalendar (OrderByName: $orderByName) {
					${calendarField}
				}
			}
		`;

		const {
			data: { GetProjectPublicHolidayCalendar }
		} = await apollo.query({
			query: QUERY_GET_PROJECT_PUBLIC_HOLIDAY_CALENDAR,
			variables: { orderByName },
			fetchPolicy: 'no-cache'
		});

		return GetProjectPublicHolidayCalendar;
	} catch (e) {
		console.log(e);
	}
};

export const updatePublicHolidayCalendar = async (PublicHolidayCalendarNumber, UpdPublicHolidayCalendar) => {
	try {
		const MUTATION_UPDATE_PUBLIC_HOLIDAY_CALENDAR = gql`
      mutation ($PublicHolidayCalendarNumber: ID!, $UpdPublicHolidayCalendar: PublicHolidayCalendarInput!){
        UpdatePublicHolidayCalendar(PublicHolidayCalendarNumber: $PublicHolidayCalendarNumber, UpdPublicHolidayCalendar: $UpdPublicHolidayCalendar) {
          ${calendarField}
        }
      }
    `;

		const {
			data: { UpdatePublicHolidayCalendar }
		} = await apollo.mutate({
			mutation: MUTATION_UPDATE_PUBLIC_HOLIDAY_CALENDAR,
			variables: {
				PublicHolidayCalendarNumber: parseInt(PublicHolidayCalendarNumber, 10),
				UpdPublicHolidayCalendar
			}
		});

		return UpdatePublicHolidayCalendar;
	} catch (e) {
		console.log(e);
	}
};

export const newPublicHolidayCalendar = async (NewPublicHolidayCalendarInput) => {
	try {
		const MUTATION_NEW_PUBLIC_HOLIDAY_CALENDAR = gql`
      mutation ($NewPublicHolidayCalendar: PublicHolidayCalendarInput!){
        NewPublicHolidayCalendar(NewPublicHolidayCalendar: $NewPublicHolidayCalendar) {
          ${calendarField}
        }
      }
    `;

		const {
			data: { NewPublicHolidayCalendar }
		} = await apollo.mutate({
			mutation: MUTATION_NEW_PUBLIC_HOLIDAY_CALENDAR,
			variables: {
				NewPublicHolidayCalendar: NewPublicHolidayCalendarInput
			}
		});

		return NewPublicHolidayCalendar;
	} catch (e) {
		console.log(e);
	}
};

export const delPublicHolidayCalendar = async (PublicHolidayCalendarNumber) => {
	try {
		const MUTATION_DEL_PUBLIC_HOLIDAY_CALENDAR = gql`
			mutation ($PublicHolidayCalendarNumber: ID!) {
				DelPublicHolidayCalendar(PublicHolidayCalendarNumber: $PublicHolidayCalendarNumber)
			}
		`;

		const {
			data: { DelPublicHolidayCalendar }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_PUBLIC_HOLIDAY_CALENDAR,
			variables: {
				PublicHolidayCalendarNumber: parseInt(PublicHolidayCalendarNumber, 10)
			}
		});

		return DelPublicHolidayCalendar;
	} catch (e) {
		console.log(e);
	}
};

export const getPublicHoliday = async (PublicHolidayId) => {
	try {
		const QUERY_GET_PUBLIC_HOLIDAY = gql`
			query ($PublicHolidayId: ID!) {
        GetPublicHoliday(PublicHolidayId: $PublicHolidayId) {
          ${publicHolidayField}
        }
			}
		`;

		const {
			data: { GetPublicHoliday }
		} = await apollo.mutate({
			query: QUERY_GET_PUBLIC_HOLIDAY,
			variables: {
				PublicHolidayId: parseInt(PublicHolidayId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetPublicHoliday;
	} catch (e) {
		console.log(e);
	}
};

export const getPublicHolidays = async (PublicHolidayCalendarNumber = null) => {
	try {
		const QUERY_GET_PUBLIC_HOLIDAYS = gql`
      query ($PublicHolidayCalendarNumber: ID) {
        GetPublicHolidays(PublicHolidayCalendarNumber: $PublicHolidayCalendarNumber) {
          ${publicHolidayField}
        }
      }
    `;

		const {
			data: { GetPublicHolidays }
		} = await apollo.query({
			query: QUERY_GET_PUBLIC_HOLIDAYS,
			variables: {
				PublicHolidayCalendarNumber: parseInt(PublicHolidayCalendarNumber, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetPublicHolidays;
	} catch (e) {
		console.log(e);
	}
};

export const delPublicHoliday = async (PublicHolidayId) => {
	try {
		const MUTATION_DEL_PUBLIC_HOLIDAY = gql`
			mutation ($PublicHolidayId: ID!) {
				DelPublicHoliday(PublicHolidayId: $PublicHolidayId)
			}
		`;

		const {
			data: { DelPublicHoliday }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_PUBLIC_HOLIDAY,
			variables: {
				PublicHolidayId: parseInt(PublicHolidayId, 10)
			}
		});

		return DelPublicHoliday;
	} catch (e) {
		console.log(e);
	}
};

export const newPublicHoliday = async (NewPublicHolidayInput) => {
	try {
		const MUTATION_NEW_PUBLIC_HOLIDAY = gql`
			mutation ($NewPublicHoliday: PublicHolidayInput!) {
        NewPublicHoliday(NewPublicHoliday: $NewPublicHoliday) {
          ${publicHolidayField}
        }
			}
		`;

		const {
			data: { NewPublicHoliday }
		} = await apollo.mutate({
			mutation: MUTATION_NEW_PUBLIC_HOLIDAY,
			variables: {
				NewPublicHoliday: NewPublicHolidayInput
			}
		});

		return NewPublicHoliday;
	} catch (e) {
		console.log(e);
	}
};

export const updatePublicHoliday = async (PublicHolidayId, UpdPublicHoliday) => {
	try {
		const MUTATION_UPDATE_PUBLIC_HOLIDAY = gql`
      mutation ($PublicHolidayId: ID!, $UpdPublicHoliday: PublicHolidayInput!) {
        UpdatePublicHoliday(PublicHolidayId: $PublicHolidayId, UpdPublicHoliday: $UpdPublicHoliday) {
          ${publicHolidayField}
        }
      }
    `;

		const {
			data: { UpdatePublicHoliday }
		} = await apollo.mutate({
			mutation: MUTATION_UPDATE_PUBLIC_HOLIDAY,
			variables: {
				PublicHolidayId: parseInt(PublicHolidayId, 10),
				UpdPublicHoliday
			}
		});

		return UpdatePublicHoliday;
	} catch (e) {
		console.log(e);
	}
};
