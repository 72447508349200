var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-center align-items-center" },
    [
      _c(
        "div",
        { attrs: { id: _vm.generateTextId(`btn_edit_${_vm.data.item.id}`) } },
        [
          _c(
            "button",
            {
              staticClass: "btn-transparent text-danger",
              on: {
                click: function ($event) {
                  return _vm.handleUpdate(_vm.data)
                },
              },
            },
            [
              _c(_vm.getLucideIcon(_vm.ICONS.EDIT.name), {
                tag: "component",
                attrs: {
                  color: _vm.ICONS.EDIT.color,
                  size: 20,
                  "stroke-width": 2,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: { display: "none" },
          attrs: { id: _vm.generateTextId(`btn_save_${_vm.data.item.id}`) },
        },
        [
          _c(
            "button",
            {
              staticClass: "btn-transparent text-danger",
              attrs: { id: _vm.generateTextId(`tooltip_${_vm.data.item.id}`) },
              on: {
                click: function ($event) {
                  return _vm.handleSave(_vm.data.item.id)
                },
              },
            },
            [
              _c(_vm.getLucideIcon(_vm.ICONS.CHECK_SQUARE.name), {
                tag: "component",
                attrs: { color: "#3C7442", size: 20, "stroke-width": 2 },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-tooltip",
        {
          attrs: {
            target: _vm.generateTextId(`tooltip_${_vm.data.item.id}`),
            show: "",
            placement: "left",
          },
        },
        [_vm._v(_vm._s(_vm.FormMSG(1, "Save or Escape")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }