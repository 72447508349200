<template>
	<div class="w-100">
		<div :id="generateTextId(`${idName}_div_${data.item.id}`)" style="display: none" class="w-100">
			<b-form-input
				:id="generateTextId(`${idName}_input_${data.item.id}`)"
				size="sm"
				:value="data.item[valueAttribute]"
				:placeholder="placeholder"
				class="w-100"
				:class="{
					'is-invalid': isSubmitted && error
				}"
				@keyup="setValidData(data.item.id)"
			/>
		</div>
		<div :id="generateTextId(`${idName}_inline_${data.item.id}`)">{{ data.item[valueAttribute] }}</div>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { generateSecureId } from '@/shared/utils';

export default {
	name: 'PayrollCodeInlineInput',
	props: {
		data: {
			type: Object,
			required: false,
			default: () => {}
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		idName: {
			type: String,
			required: true,
			default: null
		},
		valueAttribute: {
			type: String,
			required: true,
			default: null
		},
		error: {
			type: Boolean,
			require: false
		},
		isSubmitted: {
			type: Boolean,
			require: false
		}
	},
	mixins: [LanguageMessages, GlobalMixin],
	methods: {
		generateTextId(id) {
			return generateSecureId(id);
		},
		setValidData(id) {
			if (this.valueAttribute === 'code') {
				this.$emit('payroll-code-inline-input:valid-data', id);
			} else {
				this.$emit('payroll-code-inline-input:valid-data', null);
			}
		}
	}
};
</script>
