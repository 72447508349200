var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _c(
      "div",
      {
        staticClass: "w-100",
        staticStyle: { display: "none" },
        attrs: {
          id: _vm.generateTextId(`${_vm.idName}_div_${_vm.data.item.id}`),
        },
      },
      [
        _c("b-form-input", {
          staticClass: "w-100",
          class: {
            "is-invalid": _vm.isSubmitted && _vm.error,
          },
          attrs: {
            id: _vm.generateTextId(`${_vm.idName}_input_${_vm.data.item.id}`),
            size: "sm",
            value: _vm.data.item[_vm.valueAttribute],
            placeholder: _vm.placeholder,
          },
          on: {
            keyup: function ($event) {
              return _vm.setValidData(_vm.data.item.id)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        attrs: {
          id: _vm.generateTextId(`${_vm.idName}_inline_${_vm.data.item.id}`),
        },
      },
      [_vm._v(_vm._s(_vm.data.item[_vm.valueAttribute]))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }